import React,{useEffect,useState} from "react";
import TrainingCards from "./TrainingCards";
import { getAllTrainingPackages} from '../../Api' 


export default function Training() {


    const data = [
        {
           title : 'ISO 9001',
           imageUrl : 'https://image.freepik.com/free-vector/iso-certification-illustration-with-people-notepad_23-2148690777.jpg',
            desc : 'Quality Management System (Regular)',
      
            
        },{
            title : 'ISO 14001',
            imageUrl : 'https://image.freepik.com/free-photo/group-businesspeople-placing-their-hands-globe_23-2147826545.jpg',
            desc : 'Environmental Management System',
           
        }
        ,{
            title : 'ISO 45001',
            imageUrl : 'https://image.freepik.com/free-vector/insurance-protection-from-financial-loss-risk-management-health-life-property-income-insurance_335657-839.jpg',
            desc : 'Health and Safety Management System',
           
        }
        ,{
            title : 'ISO 13485',
            imageUrl : 'https://image.freepik.com/free-vector/medical-treatment-tools-composition_1284-16379.jpg',
            desc : 'Medical Devices Quality Management',
         
        }
    ]

const [packagesData, setpackagesData] = useState([])



useEffect(() => {

getTrainingPackages() ;

  return () => { }
}, [])


const getTrainingPackages = async()=>{
  const res = await getAllTrainingPackages() ;
  console.log('Training Packages are=>',res.data)
 
  setpackagesData(res.data)
}

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-3 col-sm-12">
            {/* section title */}
            <div className="section-title text-center with-desc clearfix">
              <div className="">
                {/* <h5>how it work</h5> */}
                <h2 className="title" style={{ margin : '10px' }}>
                  Our Trainings
                </h2>
              </div>
            </div>
            {/* section title end */}
          </div>
        </div>
      </div>



<div className="container-fluid" 
style={{height : 'auto' , minHeight : '100vh' , marginBottom : '10vh',paddingLeft : '5vw',paddingRight : '5vw'}}>
    <div className="row">



{ !packagesData && <div className="d-flex justify-content-center align-middle" style={{ marginTop : '10vh' }} >
        <div className="spinner-border text-primary" role="status">
         
        </div>
        <span > &nbsp; Loading...</span>
      </div> }

{packagesData && packagesData.map((item)=>{
    return(
<div className="col-lg-3 col-md-6" key={item._id}>
    <TrainingCards data={item} />
    </div>

    )
})}







    </div>
</div>

<center>
{ packagesData && <div><br/>
<br/>
    <a href="https://wa.link/iot87d">
    <button className=" content-page-button" style={{ backgroundColor: "#00bfa5" }}>
    And Many More...
    </button>
  </a>
  </div>
}
</center>
     
    </div>
  );
}
