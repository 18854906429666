import React, { useRef, useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { trainingPackageContent } from "../ConsultancyCards/Atoms";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function ConsultancyInnerPage() {
  const [
    currenttrainingPackageContent,
    setcurrenttrainingPackageContent,
  ] = useRecoilState(trainingPackageContent);


  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
     
    }
  }, [1])
  

  // console.log("Content page for full object", currenttrainingPackageContent);



var slides = 3 ;

  const [contentData, setcontentData] = useState(null);

  useEffect(() => {
 




    if (currenttrainingPackageContent.length > 0) {
      setcontentData(currenttrainingPackageContent[0].subCategoryId);
      console.log(
        "Content page for",
        currenttrainingPackageContent[0].subCategoryId
      );
if(currenttrainingPackageContent[0].subCategoryId.photos.length<2){
 slides=1;
}

    } else {
      alert("No Content Available");
    }
    return () => {};
  }, []);

  return (
    <div style={{ color: "#464646" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {contentData && (
              <div style={{ padding: "20px" }}>
                <div>
                  <img
                    src={contentData.thumbnail}
                    style={{
                      width: "100%",
                      maxHeight: "50vh",
                      objectFit: "contain",
                    }}
                  />

                  <center>
                    <h3 style={{ color: "#0B0C26" }}>
                      {" "}
                      <br />
                      {contentData.SubCategoryName}
                    </h3>
                  </center>
                </div>

                <div className="row">

                  <br/>
                  <div className="col-lg-12 col-sm-12 " style={{ display : 'flex',flexDirection : 'row' ,
                justifyContent : 'space-around'
                }}>
                    {currenttrainingPackageContent &&
                      currenttrainingPackageContent.map((item, index) => {
                        return (
                          <div  >
                            <button
                              key={index}
                              className=" content-page-button"
                              onClick={() => setcontentData(item.subCategoryId)}
                            >
                              {item.subCategoryId.SubCategoryName}
                            </button>
                          </div>
                        );
                      })}
                  </div>

                  <br />

                        <div className="col-lg-2"></div>
                  <div className="col-lg-10 ">
                    <br />
                    <br />
                 
                    <div
                      style={{ fontSize: "16px" }}
                      dangerouslySetInnerHTML={{ __html: contentData.content }}
                    ></div>


<br/>
<br/>

                    <div>
                      <a href="https://wa.link/iot87d">
                        <button
                          className=" content-page-button"
                          style={{ backgroundColor: "#00bfa5" }}
                        >
                          {" "}
                          Get this training.{" "}
                        </button>
                      </a>{" "}
                    </div>
                    <br />




<h4 style={{ color: "#0B0C26" }}>Training Slides</h4>

              


                    <OwlCarousel
                      className="owl-theme"
                      autoPlay={true}
                      autoplay
                      autoplaySpeed={500}
                      autoplayTimeout={3000}
                      loop
                      margin={20}
                      nav
                    >



                      {contentData.photos  && contentData.photos.length>2 && 
                        contentData.photos.map((item, index) => {
                        
                          return <img src={item} key={index} style={{ width : '300px' }} />;
                          
                        })}


                    </OwlCarousel>

                        { contentData.photos && contentData.photos.length<2 && <img src={contentData.photos[0]}  style={{ width : '400px' }} />  }



                    <br />
                



                    
<div>
  <a href="https://wa.link/iot87d">
    <button
      className=" content-page-button"
      style={{ backgroundColor: "#00bfa5" }}
    >
      
     Buy Now
    </button>
  </a>{" "}
</div>
<br />

                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
