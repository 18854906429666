import React, { useState, useEffect } from "react";
import "./upcomingtraining.css";
import { getUpcomingTrainings } from "../../Api";

export default function UpcomingTraining() {
  window.scrollTo(0, 0);

  const [upcomingTrainings, setupcomingTrainings] = useState(null);

  useEffect(() => {
    getTrainings();
    return () => {};
  }, []);

  const getTrainings = async () => {
    const res = await getUpcomingTrainings();
    setupcomingTrainings(res.data);
    console.log("Trainings are", res.data);
  };

  return (
    <div>


<center>
          <h2>Upcoming Trainings</h2>
        </center>

      <div className="container-fluid ">
       


        <div className="row" style={{ marginTop: "0vh" }}>

<div className="col-12">

          {upcomingTrainings &&
            upcomingTrainings.map((item, index) => {
              return (
                <div
                  className="col-sm-10 col-md-6 col-lg-4 upcard-fullcard"
                  key={index}
                >
                  <div className="upcard-heading-div">
                    <h5 style={{ color: "white", marginBottom: 0 }}>
                      {item.trainingHeading}
                    </h5>
                  </div>
                  <div className="upcard-content-div">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        {" "}
                        <strong>Training Date : </strong> {item.date}
                      </li>
                      <li class="list-group-item">
                        {" "}
                        <strong>Training Time : </strong> {item.time}
                      </li>
                      <li class="list-group-item">
                        <strong>Price for Training : </strong>
                        {item.price}
                      </li>

                      {item.trainer && (
                        <li class="list-group-item">
                          <strong>Trainer Name : </strong> {item.trainer}
                        </li>
                      )}

                      {item.trainer && (
                        <li class="list-group-item">
                          <strong>Language : </strong> {item.language}
                        </li>
                      )}

                      {item.trainer && (
                        <li class="list-group-item">
                          <strong>Exam Date : </strong> {item.examdate}
                        </li>
                      )}
                    </ul>
                  </div>

                  <center>
                    {" "}
                    <div className="upcard-subscribe-button">
                      <a
                        href="https://wa.link/iot87d"
                        class="btn btn-primary upcard-button-style"
                        style={{
                          backgroundColor: "#b9853f",
                          border: "none",
                          padding: "8px 30px",
                        }}
                      >
                        Register Now
                      </a>
                    </div>{" "}
                  </center>
                </div>
              );
            })}


</div>



        </div>
      </div>
    </div>
  );
}
