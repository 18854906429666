import React, { useState, useEffect } from "react";
import "./offer.css";
import { Link } from "react-router-dom";
import { getUpcomingTrainings } from "../../Api";

export default function Offer() {
 

  return (
    <div>
      <div className="container offer-container">
        <div className="row">
          <div className="col-lg-12 col-container">
            <Link to="/upcomingtraining">
              <h5
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  marginBottom: 0,
                  color: "#b9853f",
                }}
              >
                | Our Upcoming Trainings.<span style={{color : '#0b0c26'}}>Read Here |</span>
              </h5>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
