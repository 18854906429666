import axios from 'axios'

const Base_Url = 'https://api.mehrconsultants.com/api/v1/secret/admin/'

export const getAllTestimonials = async()=>{
    const res = await axios.get(`${Base_Url}/getAllTestimonial`)
    return res ;
}


export const getAllTrainingPackages = async()=>{
    const res = await axios.get(`${Base_Url}/getAllTrainingPackages`)
    return res ;
}


export const getAllPhotos = async()=>{
    const res = await axios.get(`${Base_Url}/getAllPhotos`)
    return res ;
}


export const getAllServices = async()=>{
    const res = await axios.get(`${Base_Url}/getConsultancyService`)
    return res ;
}





export const getUpcomingTrainings = async()=>{
    const res = await axios.get(`${Base_Url}/getAllUpcomingTraining`)
    return res ;
}



