import React,{useEffect} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Home.css";
import Testimonial from "../Testimonial/Testimonial";
import {Link} from 'react-router-dom'
import handshake2 from "../../assets/handshake2.jpg";
import Training from "../../assets/Training.jpg";
import PDCA from "../../assets/1.png";
import CustomerFocus from "../../assets/4.png";
import Improvement from "../../assets/3.png";
import icon1 from "./1.png";
import Offer from "./Offer";
import Timeline from "../Timeline/Timeline";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import bpsolar from "../../assets/clienticons/BP SOLAR.jpg";
import AEC from "../../assets/clienticons/AEC.png";
import AIDUK from "../../assets/clienticons/AIDUK.png";
import AHCSC from "../../assets/clienticons/AHCSC OLAYAN.png";
import ARASCO from "../../assets/clienticons/ARASCO.jpg";
import ASCEND from "../../assets/clienticons/ASCEND.png";
import ARCELOR from "../../assets/clienticons/ARCELOR MITTAL.png";
import Bahri from '../../assets/clienticons/BAHRI BOLLORE.png'
import BERTLING from '../../assets/clienticons/BERTLING.png'
import DHURUMA from '../../assets/clienticons/DHURUMA.png'
import FADECO from '../../assets/clienticons/FADECO.png'
import GULF from '../../assets/clienticons/GULF RIYADAH.png'
import INNOVATION from '../../assets/clienticons/INNOVATION.png'
import MEFC from '../../assets/clienticons/MEFC.png'
import NAQEL from '../../assets/clienticons/NAQEL.png'
import NCB from '../../assets/clienticons/NCB.png'
import OMYA from '../../assets/clienticons/OMYA.png'
import OUTU from '../../assets/clienticons/OUTU KUMPU ARMETAL.png'
import RAFAL from '../../assets/clienticons/RAFAL.png'
import SAPTCO from '../../assets/clienticons/SAPTCO.png'
import SASCO from '../../assets/clienticons/SASCO.png'
import TORATHUNA from '../../assets/clienticons/TORATHUNA.png'
import TOTAL from '../../assets/clienticons/TOTAL.png'
import WEST from '../../assets/clienticons/WEST GROUP.png'
import ZAMIL from '../../assets/clienticons/ZAMIL LUXURY ARCHITECTURE.png'
import ZAMILSTEEL from '../../assets/clienticons/ZAMIL STEEL.png'



export default function Home() {
  AOS.init();

  const logoArray = [bpsolar, AEC,  AHCSC, ARASCO, ASCEND,ARCELOR,Bahri,BERTLING,DHURUMA,
    FADECO,GULF,MEFC,NAQEL,NCB,OUTU,RAFAL,SAPTCO,SASCO,TORATHUNA,TOTAL,WEST,ZAMIL,ZAMILSTEEL];

  var responsive = {
    500: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 6,
    },
  };
  
var itemNumber = 2 ; 

useEffect(() => {
  

const getWidth = window.innerWidth ;
console.log('width' , getWidth)

if(getWidth>800){
  itemNumber = 4;
  console.log('Item number' , itemNumber)
}

  return () => {

  }
}, [])


  return (
    <div>
      {/* Home section  */}

      <div className="container-fluid homepage-main">
        <OwlCarousel
          className="owl-theme"
          autoplay={true}
          autoplaySpeed={500}
          autoplayTimeout={8000}
          nav={true}
          loop
          items={1}
        >
          <div class="item itemhome">
            <div className="row home-div homeimage1">
              <div
                className="col-md-6 col-lg-5 col-sm-12"
                style={{ paddingLeft: "5vw", marginTop: "-5vh" }}
              >
                <h2>Your Business, Your Standards. Our Consultancy !</h2>
                <h5 className="homepage-subhead">
               We are Specialists in <strong>API</strong> & other standards implementation for your business.
                </h5>

                <div>
               <Link to="/consultancy"><button className="cta-button">Get Certified</button></Link>   
                </div>
              </div>
            </div>
          </div>
          

          <div class="item itemhome">
            <div className="row home-div homeimage2">
              <div
                className="col-md-6 col-lg-5 col-sm-12"
                style={{ paddingLeft: "5vw", marginTop: "-5vh" }}
              >
                <h2>Your Business, Your Standards. Our Consultancy ! </h2>
                <h5 className="homepage-subhead">
                Qualified & experienced Trainers in API, ISO & other Management Systems.
                </h5>

                <div>
                <Link to="/training"> <button className="cta-button">Get Trained</button></Link>
                </div>
              </div>
            </div>
          </div>

          <div class="item itemhome">
            <div className="row home-div homeimage3">
              <div
                className="col-md-6 col-lg-5 col-sm-12 "
                style={{ paddingLeft: "5vw", marginTop: "-5vh" }}
              >
                <h2> Go Fast, Go Paperless, Go Soft Use ISOft.</h2>
                <h5 className="homepage-subhead">
                ISOft is an emerging software that manages ISO conformances &
                 business process across the value chain.
                </h5>

                <div>
                <Link to="/eqms">  <button className="cta-button">Get Software</button></Link>
                </div>
              </div>
            </div>
          </div>

          <div class="item itemhome">
            <div className="row home-div homeimage4">
              <div
                className="col-md-8 col-lg-6 col-sm-12"
                style={{ paddingLeft: "5vw", marginTop: "-10vh" }}
              >
                <h2>Your International Trade,  Legal Issues & Our Legal Advice.</h2>
                <h5 className="homepage-subhead">
                  We are the Most Trusted Legal Advisor with 18+ Years of
                  Experience.
                </h5>

                <div>
                <Link to="/legal"> <button className="cta-button">Get Legal Advice</button></Link>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>

      {/* home section ends here */}

      <div>
        <Offer />
      </div>

      {/* about-section */}
      <section
        className="ttm-row about2-section clearfix"
        style={{ marginTop: "5vh" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-12">
              <div className="position-relative pr-15 res-991-pr-0">
                <div className="row mb-30">
                  <div
                    className="col-md-6"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    }}
                  >
                    {/* ttm_single_image-wrapper */}
                    <div className="ttm_single_image-wrapper ttm-991-center">
                      <img
                        className="img-fluid"
                        src={PDCA}
                        style={{ maxWidth: "150px" }}
                        title="single-img-three"
                        alt="single-img-three"
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    }}
                  >
                    {/* ttm_single_image-wrapper */}
                    <div className="ttm_single_image-wrapper ttm-991-center res-767-mt-30">
                      <img
                        className="img-fluid"
                        src={CustomerFocus}
                        style={{ maxWidth: "150px" }}
                        title="single-img-four"
                        alt="single-img-four"
                      />
                    </div>
                    {/* ttm_single_image-wrapper end */}
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-12"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="position-relative">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper w100">
                        <img
                          className="img-fluid"
                          src={Improvement}
                          style={{ maxWidth: "400px" }}
                          title="single-img-five"
                          alt="single-img-five"
                        />
                      </div>
                      {/* ttm_single_image-wrapper end */}
                      {/*ttm-fid*/}
                      <div className="ttm-fid inside ttm-fid-view-lefticon ttm-highlight-fid-style2 myyear">
                        <div className="ttm-fid-left">
                          <div className="ttm-fid-icon-wrapper">
                            <i className="ti ti-world" />
                          </div>
                        </div>
                        <div className="ttm-fid-contents text-left">
                          <h4 className="ttm-fid-inner">
                            <span
                              data-appear-animation="animateDigits"
                              data-from={0}
                              data-to={15}
                              data-interval={5}
                              data-before
                              data-before-style="sup"
                              data-after="+"
                              data-after-style="sub"
                            >
                              18
                            </span>
                          </h4>
                          <h3 className="ttm-fid-title">Years Of Experience</h3>
                        </div>
                      </div>
                      {/* ttm-fid end*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-12">
              <div className="pl-15 res-991-pl-0 res-991-mt-30">
                {/* section title */}
                <div className="section-title pr-60 res-991-pr-0 clearfix">
                  <div className="title-header">
                    <h5>ABOUT CONSULTANCY</h5>
                    <h2 className="title">
                    We Help to grow your business.
                    </h2>
                  </div>
                  <div className="title-desc" align="justify">
                    Our team works with the mission to build, augment and infuse
                    competitive advantage in organizations through rigorous
                    mentorship and facilitation of operational excellence.
                    <br /> <br />
                    

                    <h4><strong>Our Values</strong></h4>
                  </div>
                </div>
                {/* section title end */}

                <div className="row no-gutters mt-25" >
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li style={{ marginRight : '5px' }}><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"  align='justify'><strong style={{color : '#ab762e'}}>M- Majestic,</strong>We are majestic by values and ethics. </span></li>
                        <li style={{ marginRight : '5px' }}><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"  align='justify'><strong style={{color : '#ab762e'}}>E- Ethical,</strong>We know the difference between what we have a right to do and what is right to do.</span></li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <ul className="ttm-list ttm-list-style-icon">
                        <li style={{ marginRight : '5px' }}><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"  align='justify'><strong style={{color : '#ab762e'}}>H- Honesty,</strong>We believe Honesty is  the keystone of business.</span></li>
                        <li style={{ marginRight : '5px' }}><i className="fa fa-arrow-circle-right ttm-textcolor-skincolor" /><span className="ttm-list-li-content"  align='justify'><strong style={{color : '#ab762e'}}> R- Reliability,</strong>We strongly believe that reliability is the precondition for trust in business.</span></li>
                      </ul>
                    </div>
                  </div>



                <div className="separator">
                  <div className="sep-line dashed" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-section end */}

      {/*strategy-section*/}
      <section className="ttm-row strategy-section bg-layer bg-layer-equal-height break-1199-colum clearfix">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-9 col-md-12">
              {/* col-bg-img-three */}
              <div className="col-bg-img-three ttm-col-bgimage-yes ttm-bg ttm-col-bgcolor-yes ttm-left-span ttm-bgcolor-darkgrey spacing-3">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>
                <div className="layer-content">
                  {/* section title */}
                  <div className="section-title pr-60 res-991-pr-0 clearfix">
                    <div className="title-header mb-50">
                      <h5>WHO WE ARE</h5>
                      <h2 className="title">Our Prime Services</h2>
                    </div>
                  </div>
                  {/* section title end */}
                  <div className="row">
                    <div className="col-md-6">
                      {/*featured-icon-box*/}
                     
                      <div className="featured-icon-box style9 mb-30">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <img src={icon1} />
                          </div>
                        </div>

                        <div className="featured-content">
                          <div className="featured-title">
                            <h5> Management System <br/> Maintenance</h5>
                          </div>
                        </div>
                      </div>
                      {/* featured-icon-box end*/}
                    </div>
                    <div className="col-md-6">
                      {/*featured-icon-box*/}
                      <div className="featured-icon-box style9 mb-30">
                        <div className="featured-icon">
                          {/* featured-icon*/}
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon flaticon-talk-1" />
                          </div>
                        </div>
                        {/* featured-icon */}
                        <div className="featured-content">
                          {/* featured-content */}
                          <div className="featured-title">
                            <h5>Consultancy</h5>
                          </div>
                        </div>
                        {/* featured-content */}
                      </div>
                  
                      {/* featured-icon-box end*/}
                    </div>

                    {/* <div className="col-md-6">
                    
                      <div className="featured-icon-box style9 mb-30">
                        <div className="featured-icon">
                      
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon flaticon-marketing" />
                          </div>
                        </div>
                    
                        <div className="featured-content">
                      
                          <div className="featured-title">
                            <h5> Process Improvement</h5>
                          </div>
                        </div>
             
                      </div>
                      
                    </div> */}
                    <div className="col-md-6">
                      {/*featured-icon-box*/}
                      <div className="featured-icon-box style9 mb-30">
                        <div className="featured-icon">
                          {/* featured-icon*/}
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon flaticon-business-and-finance-1" />
                          </div>
                        </div>
                        {/* featured-icon */}
                        <div className="featured-content">
                          {/* featured-content */}
                          <div className="featured-title">
                            <h5> 1st & 2nd Party Audits.</h5>
                          </div>
                        </div>
                        {/* featured-content */}
                      </div>
                      {/* featured-icon-box end*/}
                    </div>

                    <div className="col-md-6 ">
                    
                    <div className="featured-icon-box style9 mb-30">
                        <div className="featured-icon">
                          {/* featured-icon*/}
                          <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon flaticon-viral-marketing" />
                          </div>
                        </div>
                        {/* featured-icon */}
                        <div className="featured-content">
                          {/* featured-content */}
                          <div className="featured-title">
                            <h5>Training</h5>
                          </div>
                        </div>
                        {/* featured-content */}
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              {/* col-bg-img-three end*/}
            </div>
            <div className="col-lg-3 col-md-12">
              {/* col-img-img-two */}
              <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-right-span spacing-4">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content">
                  {/* <div className="ttm-video-btn ttm-right-center-video-btn">
                    <a
                      className="ttm-play-btn ttm_prettyphoto"
                      href="https://youtu.be/7e90gBu4pas"
                    >
                      <span className="ttm-video-btn-play">
                        <i className="fa fa-play" />
                      </span>
                    </a>
                  </div> */}
                </div>
              </div>
              {/* col-img-bg-img-two end*/}
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/*strategy-section end*/}
      <Timeline />
      {/* process-section */}
      <section className="ttm-row ttm-bg ttm-bgimage-yes bg-img3 process-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-1" />
            <div className="col-md-8 col-sm-10">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header">
                  <h5>how it work</h5>
                  <h2 className="title">How our Designed Management System is Different?</h2>
                </div>
              </div>
              {/* section title end */}
            </div>
            <div className="col-md-3 col-sm-1" />
          </div>
          {/* row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="row ttm-processbox-wrapper">
                <div className="col-lg-4">
                  <div className="ttm-processbox">
                    <div className="ttm-box-image">
                      <div className="process-num">
                        <span className="number">01</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h4>Innovative</h4>
                      </div>
                      <div className="ttm-box-description">
                      We developed the system with innovative Ideas and solutions.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="ttm-processbox mt-50 res-991-mb-50">
                    <div className="ttm-box-image">
                      <div className="process-num">
                        <span className="number">02</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h4>User Friendly</h4>
                      </div>
                      <div className="ttm-box-description" >
                     <p > Developed the Management System in close participation of 
                      respective Process owners resulting in effective implementation.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="ttm-processbox">
                    <div className="ttm-box-image">
                      <div className="process-num">
                        <span className="number">03</span>
                      </div>
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h4>Result Oriented</h4>
                      </div>
                      <div className="ttm-box-description">
                      Analysis of data, generated through Management System leads you towards improvement.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* process-section end */}

      <div
        className="container-fluid "
        style={{ marginTop: "0vh", padding: "50px" }}
      >
        <div className="row justify-content-center">
          <div
            className="col-md-12 col-lg-12 justify-content-center"
            style={{ width: "100vw" }}
          >

<center>
              <h1>Our Clients ↓</h1>
            </center>


            <OwlCarousel
              className="owl-theme logo-carousel"
              loop
              margin={10}
              autoplay={true}
              autoplaySpeed={1300}
              autoplayTimeout={1300}
              items={itemNumber}
              responsive={responsive}
            
              loop={true}
            >
              {logoArray &&
                logoArray.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <img
                        className="img-fluid logo-caoursel-image"
                        src={item}
                        alt="image"
                      />
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </div>

      {/* client-section */}
      <section className="ttm-row team-work-section bg-img4 clearfix">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-lg-3 col-sm-1" />
            <div className="col-md-8 col-lg-6 col-sm-10">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header mb-60">
                  <h5>WHY Business CHOOSE US</h5>
                  <h2 className="title">Our Achieved Milestones</h2>
                </div>
              </div>
              {/* section title end */}
            </div>
            <div className="col-md-2 col-lg-3 col-sm-1" />
          </div>
          <div className="row no-gutters mt-23 ttm-fid-view-topicon-row ttm-bgcolor-white">
            <div className="col-md-3 col-sm-6 with-right-border">
              <div className="ttm-fid inside ttm-fid-view-topicon">
                <div className="ttm-fid-icon-wrapper">
                  <i className="ti ti-light-bulb" />
                </div>
                <div className="ttm-fid-contents">
                  <h4>
                    <span
                      data-appear-animation="animateDigits"
                      data-from={0}
                      data-to={273}
                      data-interval={20}
                      data-before
                      data-before-style="sup"
                      data-after
                      data-after-style="sub"
                    >
                      115+
                    </span>
                  </h4>
                  <h3 className="ttm-fid-title">
                    <span>Number of Projects completed</span>
                  </h3>
                </div>
                {/* ttm-fid-contents end */}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 with-right-border">
              <div className="ttm-fid inside ttm-fid-view-topicon">
                <div className="ttm-fid-icon-wrapper">
                  <i className="ti ti-world" />
                </div>
                <div className="ttm-fid-contents">
                  <h4>
                    <span
                      data-appear-animation="animateDigits"
                      data-from={0}
                      data-to={145}
                      data-interval={20}
                      data-before
                      data-before-style="sup"
                      data-after
                      data-after-style="sub"
                    >
                      99%
                    </span>
                  </h4>
                  <h3 className="ttm-fid-title">
                    <span>Successful Certification in first audit</span>
                  </h3>
                </div>
                {/* ttm-fid-contents end */}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 with-right-border">
              <div className="ttm-fid inside ttm-fid-view-topicon">
                <div className="ttm-fid-icon-wrapper">
                  <i className="ti ti-pencil-alt" />
                </div>
                <div className="ttm-fid-contents">
                  <h4>
                    <span
                      data-appear-animation="animateDigits"
                      data-from={0}
                      data-to={891}
                      data-interval={20}
                      data-before
                      data-before-style="sup"
                      data-after
                      data-after-style="sub"
                    >
                    20+
                    </span>
                  </h4>
                  <h3 className="ttm-fid-title">
                    <span> Nationals Trained by our Trainers</span>
                  </h3>
                </div>
                {/* ttm-fid-contents end */}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 with-right-border">
              <div className="ttm-fid inside ttm-fid-view-topicon">
                <div className="ttm-fid-icon-wrapper">
                  <i className="ti ti-user" />
                </div>
                <div className="ttm-fid-contents">
                  <h4>
                    <span
                      data-appear-animation="animateDigits"
                      data-from={0}
                      data-to={547}
                      data-interval={20}
                      data-before
                      data-before-style="sup"
                      data-after
                      data-after-style="sub"
                    >
                      2500+
                    </span>
                  </h4>
                  <h3 className="ttm-fid-title">
                    <span>Auditors trained</span>
                  </h3>
                </div>
                {/* ttm-fid-contents end */}
              </div>
            </div>
          </div>

          <div className="separator">
            <div className="sep-line solid mt-50 mb-25 res-991-mb-0" />
          </div>
        </div>
      </section>
      {/* client-section end*/}

      <Testimonial />
    </div>
  );
}
