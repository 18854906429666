import React,{ useEffect,useState} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./Gallery.css";
import { getAllPhotos } from '../../Api' ;

export default function Gallery() {

// const GalleryImages = [
//     {
//         category : 'MEHR OFFICE',
//         photos : [
//          'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyAZZtVazRzLvXK8SYIGhVzrSwKkQCnbLyiQ&usqp=CAU',
//         'https://images.theconversation.com/files/349387/original/file-20200724-15-ldrybi.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=900.0&fit=crop',
//         'https://www.spectrummetro.com/blog/wp-content/uploads/2017/06/Office-Space-in-Noida.jpg',
//         'https://cdn.home-designing.com/wp-content/uploads/2018/03/home-office-setup.jpg' ]
//     },
//     {
//         category : 'Employees',
//         photos : [
//          'https://cdn2.business2community.com/wp-content/uploads/2016/02/a_339.jpg.jpg',
//         'https://careersbusiness.co.uk/wp-content/uploads/2017/04/1-3.jpg',
//         'https://y26uq11r8xr1zyp0d3inciqv-wpengine.netdna-ssl.com/wp-content/uploads/2019/10/32-1-1024x597.jpg',
//         'https://cdn.home-designing.com/wp-content/uploads/2018/03/home-office-setup.jpg' ]
//     },
    
// ]

const [GalleryImages, setGalleryImages] = useState([])

useEffect(() => {
  
  const getPhotos = async()=>{
    const res = await getAllPhotos() ;
    setGalleryImages(res.data)
  }

  getPhotos();

  // console.log('i am running')

  return () => {
    
  }
}, [])


const [currentIndex, setcurrentIndex] = useState(0)


  return (
    <div>
      <div className="container-fluid gallery-container">
        <div className="row">
          <div className="col-lg-12 ">
            <Tabs selectedIndex={currentIndex}>


              <TabList className="tablist">
                {/* <Tab className="tab">All</Tab>
                <Tab className="tab">IEF Celebration</Tab> */}

{GalleryImages && GalleryImages.map((item,index)=>{
  
   return(
      <Tab key={index} className="tab " onClick={()=>setcurrentIndex(index)} aria-selected="true" style={{ textTransform : 'uppercase' }}>{item.category}</Tab>
    )
})}
              </TabList>


{ GalleryImages && GalleryImages.map((item,index)=>{
    return(
        <TabPanel key={index} >


            <div className="image-container" style={{ overflow : 'hidden' ,  }}>
                { item.photos.map((itemImage,index)=>{
                    return (
                      <div className="col-lg-4"style={{padding:10 ,overflow : 'hidden' }}>
                        <img  src={itemImage} />
                        </div>
                    )
                }) }    
            </div>


        </TabPanel>
    )
}) }







            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
