import { atom } from "recoil";

const consultancyState = atom({
  key: "consultancyData",
  default: { data: null },
  persistence_UNSTABLE: {
    type: "consultancyData",
  },
});

const trainingPackagesCategory = atom({
  key: "trainingPackagesCategory",
  default: { data: null },
  persistence_UNSTABLE: {
    type: "trainingPackagesCategory",
  },
});



const trainingPackageContent = atom({
  key: "trainingPackageContent",
  default: { data: null },
  persistence_UNSTABLE: {
    type: "trainingPackageContent",
  },
});

export { consultancyState, trainingPackagesCategory ,trainingPackageContent};
