import React from "react";

export default function TestimonialCard({ testimonial }) {
  return (
    <div className=" testimonial-card">
      <figure class="snip1390">
        <figcaption>
          <blockquote>
            <div>{testimonial.content}</div>
          </blockquote>
        </figcaption>
        <br />
        <img
          src={testimonial.photoOfCompany}
          alt={`${testimonial.testimonial_Name}'s Picture`}
          className="profile"
        />
        <h4 style={{color:"white" }}>{testimonial.nameOfPerson}</h4>
        <h5 style={{color:"white"}}>{testimonial.nameOfCompany}</h5>
      </figure>
    </div>
  );
}
