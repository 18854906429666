import React, { useEffect } from "react";
import Testimonial from "../Testimonial/Testimonial";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import bpsolar from "../../assets/clienticons/BP SOLAR.jpg";
import AEC from "../../assets/clienticons/AEC.png";
import AIDUK from "../../assets/clienticons/AIDUK.png";
import AHCSC from "../../assets/clienticons/AHCSC OLAYAN.png";
import ARASCO from "../../assets/clienticons/ARASCO.jpg";
import ASCEND from "../../assets/clienticons/ASCEND.png";
import ARCELOR from "../../assets/clienticons/ARCELOR MITTAL.png";
import Bahri from "../../assets/clienticons/BAHRI BOLLORE.png";
import BERTLING from "../../assets/clienticons/BERTLING.png";
import DHURUMA from "../../assets/clienticons/DHURUMA.png";
import FADECO from "../../assets/clienticons/FADECO.png";
import GULF from "../../assets/clienticons/GULF RIYADAH.png";
import INNOVATION from "../../assets/clienticons/INNOVATION.png";
import MEFC from "../../assets/clienticons/MEFC.png";
import NAQEL from "../../assets/clienticons/NAQEL.png";
import NCB from "../../assets/clienticons/NCB.png";
import OMYA from "../../assets/clienticons/OMYA.png";
import OUTU from "../../assets/clienticons/OUTU KUMPU ARMETAL.png";
import RAFAL from "../../assets/clienticons/RAFAL.png";
import SAPTCO from "../../assets/clienticons/SAPTCO.png";
import SASCO from "../../assets/clienticons/SASCO.png";
import TORATHUNA from "../../assets/clienticons/TORATHUNA.png";
import TOTAL from "../../assets/clienticons/TOTAL.png";
import WEST from "../../assets/clienticons/WEST GROUP.png";
import ZAMIL from "../../assets/clienticons/ZAMIL LUXURY ARCHITECTURE.png";
import ZAMILSTEEL from "../../assets/clienticons/ZAMIL STEEL.png";

export default function ClientReview() {
  const logoArray = [
    bpsolar,
    AEC,
    AIDUK,
    AHCSC,
    ARASCO,
    ASCEND,
    ARCELOR,
    Bahri,
    BERTLING,
    DHURUMA,
    FADECO,
    GULF,
    INNOVATION,
    MEFC,
    NAQEL,
    NCB,
    OMYA,
    OUTU,
    RAFAL,
    SAPTCO,
    SASCO,
    TORATHUNA,
    TOTAL,
    WEST,
    ZAMIL,
    ZAMILSTEEL,
  ];

  var responsive = {
    500: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 6,
    },
  };

  var itemNumber = 2;

  useEffect(() => {
    const getWidth = window.innerWidth;
    console.log("width", getWidth);

    if (getWidth > 800) {
      itemNumber = 4;
      console.log("Item number", itemNumber);
    }

    return () => {};
  }, []);

  return (
    <div>
      <Testimonial />

      <div
        className="container-fluid "
        style={{ height: "50vh", marginTop: "5vh", padding: "50px" }}
      >
        <div className="row justify-content-center">
          <div
            className="col-md-12 col-lg-12 justify-content-center"
            style={{ width: "100vw" }}
          >




<center>
              <h1>Our Clients ↓</h1>
            </center>




            <OwlCarousel
              className="owl-theme logo-carousel"
              loop
              margin={20}
              autoplay={true}
              autoplaySpeed={1500}
              autoplayTimeout={1500}
              items={itemNumber}
              responsive={responsive}
              loop={true}
            >
              {logoArray &&
                logoArray.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <img
                        className="img-fluid logo-caoursel-image"
                        src={item}
                        alt="image"
                      />
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </div>

      <div className="container" style={{ textAlign : 'center' }}>
        <div className="row ">
       

          <div className="col-md-3 col-sm-2" />
          <div className="col-md-6 col-sm-8">
          <h4>Give your feedback.</h4>

            <div className>
              <form
                id="subscribe-form"
                className="newsletter-form"
                method="post"
                action="#"
                data-mailchimp="true"
              >
                <div
                  className="mailchimp-inputbox clearfix"
                  id="subscribe-content"
                >
                  <i className="fa fa-envelope-o" />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Feedback.."
                    required
                  />
                  <input type="submit" defaultValue="SUBMIT" />
                </div>
                <div id="subscribe-msg" />
              </form>
            </div>
          </div>
          <div className="col-md-3 col-sm-2" />
        </div>
        <br/>
      </div>




    </div>
  );
}
