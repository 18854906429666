import React, { useEffect, useState } from "react";
import "./testimonial.css";
import { getAllTestimonials } from "../../Api";
import OwlCarousel from "react-owl-carousel";
import TestimonialCard from "./TestimonialCard";

export default function Testimonial() {
  // const testimonialData = [
  //   {
  //     testimonial_Content:
  //       "The strength to change what I can, the inability to accept what I can not and the incapacity to tell the difference.",
  //     testimonial_Name: "Sui Chan",
  //     testimonial_Company: "Schbang",
  //     testimonial_Image:
  //       "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample6.jpg",
  //   },
  //   {
  //     testimonial_Content:
  //       "The Professional trainings available at MEHR GLobal are one of the Best in the Industry.Amazing Work.",
  //     testimonial_Name: "Robert Diego",
  //     testimonial_Company: "Tata Consultancy",
  //     testimonial_Image:
  //       "https://images.pexels.com/photos/2269872/pexels-photo-2269872.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  //   },
  //   {
  //     testimonial_Content:
  //       "I started my Consultancy at Mehr and now we are touching Sky Rocket Sales just in 6 Months.",
  //     testimonial_Name: "Joseph King",
  //     testimonial_Company: "Eva Ecommerce",
  //     testimonial_Image:
  //       "https://images.pexels.com/photos/3211476/pexels-photo-3211476.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  //   },
  // ];

  const [testimonialData, settestimonialData] = useState(null);

  useEffect(() => {
    const gettingTestimonials = async () => {
      const res = await getAllTestimonials();
      console.log("Testimonial Data =>", res.data);
      settestimonialData(res.data);
    };

    gettingTestimonials();
    console.log("testimonial data after setting", testimonialData);

    return () => {};
  }, [1]);

  var responsive = {
    500: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 4,
    },
  }

  return (
    <div>
      {/* element-style-section */}
      <section className="element-row element-style testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title clearfix">
                <center>    <h2 className="title">Client Testimonials</h2></center>
             
              </div>
            </div>
          </div>
        </div>

      
          <div className="container-fluid">
            <div className="row"  style={{ padding : '20px'}}>
              <div>
                {testimonialData && (
                  <OwlCarousel
                    className="owl-theme"
                    autoplaySpeed={1500}
                    autoplayTimeout={3000}
                  margin={50}
                    loop
                    autoplay={true}
                    responsive={responsive}
                  >
                    {
                      testimonialData.map((testimonial, index) => {
                        return (
                         <TestimonialCard testimonial={testimonial} key={index} />
                        );
                      })}
                  </OwlCarousel>
                )}
              </div>

             
            </div>
          </div>
     
      </section>
    </div>
  );
}
