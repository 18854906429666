import React  from "react";
import {Link} from 'react-router-dom'
import { useRecoilState } from "recoil";
import { trainingPackageContent,trainingPackagesCategory } from "../ConsultancyCards/Atoms";


export default function SubTrainingCard({ data }) {
 
  const [selectedtrainingPackagesCategory, setselectedtrainingPackagesCategory] = useRecoilState(trainingPackagesCategory);
  const [currenttrainingPackageContent, setcurrenttrainingPackageContent] = useRecoilState(trainingPackageContent);


  console.log('I am in subtraining page',selectedtrainingPackagesCategory)

  return (
    <div>
      <div className="card iso-card" >
        <img
        style={{objectFit:'cover' }}
          className="card-img-top"
          src={data.thumbnail}
          alt="Card image cap" />
        <div className="card-body">
          {/* <h5 className="card-title">{data.title}</h5> */}
      
  <p className="card-text" style={{color : '#232323'}}>{data.categoryName}</p>
          
          <a href="/subtrainingcontentpage" onClick={()=>setcurrenttrainingPackageContent(data.listOfSubCategory)}>
          <span className="btn btn-primary">
            View Details
          </span>
          </a>
        </div>
      </div>
    </div>
  );
}
