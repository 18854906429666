import React from "react";
import OwlCarousel from "react-owl-carousel";
import './pages.css'

export default function Legal() {
  return (
    <div>
      <div className="ttm-page-title-row1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-box ttm-textcolor-white">
                <div className="page-title-heading">
                  <h1 className="title">Legal Services</h1>
                </div>
                {/* /.page-title-captions */}
                <div className="breadcrumb-wrapper">
                  <span>
                    <a title="Homepage" href="index.html">
                      <i className="ti ti-home" />
                      &nbsp;&nbsp;Home
                    </a>
                  </span>
                  <span >&nbsp; | &nbsp;</span>
                  <span style={{color : 'white'}}>Legal Services</span>
                </div>
              </div>
            </div>
            {/* /.col-md-12 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </div>

      <div className="container  legalcontainer">
        <div className="legalheading">
          <h3 className="title">1. International Trade Law</h3>
          <div className="legalcontent">
            <p align="justify">
              Dispute resolution involving international Laws with
              Specialization in International Chamber of Commerce (ICC) Rules on
              Documentary Credits (UCP 600), & all related litigation dealing.
              Advise, drafting and legal vetting of various agreements such as
              Contracts of Sale of goods, Service Provider Agreements, License &
              Franchise Agreements, International Distribution & Agency
              Agreements, Irrevocable Master Fee Protection Agreements, Non
              Circumvention & Non-Disclosure Agreements, Confidentiality
              Agreements, Employment Agreements, Concession Agreements, EPC
              Agreements & other MOU’s and all related litigation.
            </p>
          </div>
        </div>
        <div className="legalheading">
          <h3 className="title">2. Contract, agreement and MOA formulation</h3>
          <div className="legalcontent">
            <p align="justify">
              Formal signed agreements or contracts are also useful, and can be
              essential, for when service problems or failures occur, or for
              when the customer's or client's requirements change one way or
              another. Proper contracts and agreements provide an essential
              reference point upon which to discuss and negotiate effective
              outcomes whenever situations change, in terms of the client's
              requirements and the provider's capability. The process of
              agreeing a contract is therefore, aside from anything else, an
              excellent way to flush out and make transparent all aspects of the
              supply or service arrangement, much of which is otherwise commonly
              'taken for granted', usually including many wrong or mismatched
              assumptions on both sides. A good trading contract enables such
              risks to be averted.
            </p>
          </div>
        </div>
        <div className="legalheading">
          <h3 className="title">3. Legal Advising and Consultation</h3>
          <div className="legalcontent">
            <p align="justify">
              Legal advice is the giving of a professional or formal opinion
              regarding the substance or procedure of the law in relation to a
              particular factual situation. Legal advice is ordinarily provided
              in exchange for financial or other tangible compensation. Advice
              given without remuneration is normally referred to as being pro
              bono publico (in the public good), or simply pro bono. In the
              common law systems it is usually received from a solicitor,
              barrister or lawyer; in civil law systems it is given by
              advocates, lawyers or other professionals (such as tax experts,
              professional advisors, etc.)
            </p>
          </div>
        </div>
        <div className="legalheading">
          <h3 className="title">4. Litigation and Drafting</h3>
          <div className="legalcontent">
            <p align="justify">
              Advise, drafting and legal vetting of various agreements such as
              Contracts of Sale of goods, Service Provider Agreements, License &
              Franchise Agreements, International Distribution & Agency
              Agreements, Irrevocable Master Fee Protection Agreements,
              Non-Circumvention & Non-Disclosure Agreements, Confidentiality
              Agreements, Employment Agreements, Concession Agreements, EPC
              Agreements & other MOU’s and all related litigation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
